import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import Header from "../components/Header";
import Footer from "../components/Footer";
import MapRenderer from "../components/MapRenderer";

import citydata from "../data";

const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [goToCartCount, setGoToCartCount] = useState(0);

  const [serverRefresh, setServerRefresh] = useState(true);

  // const baseurl = 'http://localhost:5226/api';
  // const baseurl = 'https://napco.borninteractive.net/api';
  const baseurl = "https://www.woosooqconnect.com:8990/api";

  const fetchData = async () => {
    // console.log("getting data from API");

    try {
      const response = await fetch(baseurl + "/app/citypins");
      const result = await response.json();
      // console.log("Response:", result); // Log the response to inspect its structure

      // Store data in localStorage
      const cacheData = {
        dateSaved: new Date().toISOString(), // Convert date to string for storage
        data: result.data.city,
      };

      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (!isSafari) {
        localStorage.setItem("city", JSON.stringify(cacheData));
      }

      setData(result.data.city);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // const cacheData = {
    //     dateSaved: new Date().toISOString(), // Convert date to string for storage
    //     data: citydata
    // };
    // localStorage.setItem('city', JSON.stringify(cacheData));
    // setData(citydata)
  };

  const handleGoToCart = () => {
    setGoToCartCount((prevCount) => prevCount + 1);
  };

  const location = useLocation();
  const { profileid, orgid, siteid } = queryString.parse(location.search);

  const [allImagesarray, setAllImagesArray] = useState([]);

  useEffect(() => {
    async function getServerCacheStatus() {
      try {
        const response = await fetch(baseurl + "/app/CityPinsCached");
        const result = await response.json();
        // console.log("getting server cache status...", result.data.isCached);
        setServerRefresh(result.data.isCached);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    getServerCacheStatus();
  }, []);

  useEffect(() => {
    // console.log("serverRefresh", serverRefresh);
    const savedDATA = JSON.parse(localStorage.getItem("city"));

    if (serverRefresh !== null) {
      if (!serverRefresh) {
        fetchData();
      } else {
        if (
          savedDATA !== null &&
          savedDATA.dateSaved !== null &&
          savedDATA.data !== null &&
          savedDATA.data !== undefined
        ) {
          // check if date is more than 1 month
          const savedTimestamp = new Date(savedDATA.dateSaved);
          const currentDate = new Date();
          const timeDifference = currentDate - savedTimestamp;
          const maxCacheTime = 5 * 24 * 60 * 60 * 1000; // 5 days- cache period
          const isMoreThanAllowedCachingPeriod = timeDifference > maxCacheTime;

          if (isMoreThanAllowedCachingPeriod) {
            // console.log("sendingToServer");
            fetchData();
          } else {
            // console.log("getting data from cache");
            setData(savedDATA.data);
          }
        } else {
          fetchData();
        }
      }
    }
  }, [serverRefresh]);

  useEffect(() => {
    // console.log("Data:", data);

    const getAllImagesArray = (node, imagesArray) => {
      if (node.image && node.products == null) {
        imagesArray.push({ id: node.id, url: node.image });
      }
      if (node.pins && node.pins != null && node.pins.length > 0) {
        node.pins.forEach((pin) => getAllImagesArray(pin, imagesArray));
      }
    };

    const imagesArray = [];
    setAllImagesArray(imagesArray);
    if (data != null) {
      getAllImagesArray(data, imagesArray);
    }

    setLoading(false);
  }, [data]);

  useEffect(() => {
    const isRunningInIframe = window.self !== window.top;

    //test
    // const allowedParentLink = "https://woosooqconnect.com/";
    //live
    // const allowedParentLink = "www.commerce.napconational.com";

    //these 3 parameters in the url are required to know if we are in the ecommerce mode
    if (profileid && orgid && siteid) {
      if (
        isRunningInIframe &&
        (document.referrer == "https://woosooqconnect.com/" ||
          document.referrer == "https://www.woosooqconnect.com/" ||
            document.referrer == "https://woosooq.com/" ||
            document.referrer == "https://www.woosooq.com/")
      ) {
        // console.log("setting configuration...");
        localStorage.setItem("profileid", profileid);
        localStorage.setItem("orgid", orgid);
        localStorage.setItem("siteid", siteid);
        localStorage.setItem("mode_isEcommerce", true);
        if (localStorage.getItem("selectedLanguage") == "ar") {
          // console.log('logoSet')
          localStorage.setItem("logo", "/logoWoosooq-2Ar.png");
          // localStorage.setItem('logo', '/logonapco-2.png')
        } else {
          // console.log('logoSet')
          localStorage.setItem("logo", "/logoWoosooq-2En.png");
          // localStorage.setItem('logo', '/logonapco-2.png')
        }
      } else {
        //if not running in an iFrame then redirect to the catalogue site
        // window.location.href = window.location.origin + window.location.pathname;
        window.location.href = "https://b2bgate.napconational.com/";
      }
    } else {
      localStorage.setItem("mode_isEcommerce", false);

      if (localStorage.getItem("selectedLanguage") == "ar") {
        localStorage.setItem("logo", "/logonapco-3-ar.png");
      } else {
        localStorage.setItem("logo", "/logonapco-3.png");
      }
    }
  }, [profileid, orgid, siteid]);

  const [targetNode, setTargetNode] = useState(null);

  const handleJump = (node) => {
    setTargetNode(node);
  };

  return (
    <div>
      <Header sendToParentJumpToNode={handleJump} />

      {!loading && data && (
        <MapRenderer
          jumpToNode={targetNode}
          onGoToCart={handleGoToCart}
          city={data}
          imagesArray={allImagesarray}
        />
      )}

      <Footer opencart={goToCartCount} />
    </div>
  );
};

export default Home;
